<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Партнёры

        vuecrud(selectedtable = "franchises", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:popupHeader="binds")
            div.datatable-modal-container(style="background-color:#3c4b64; color: white; padding: 16px")
              h5.modal-title ДОБАВЛЕНИЕ ФРАНШИЗЫ
          //template(v-slot:Popup="popupInfo")

</template>

<script>

export default {
  name: 'Franchises',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: true,
        canEdit: true,
        canDelete: true
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.set('name',{label: 'Название'})
      cols.set('id',{label: 'ID', isShowOnPopup: false, isEdit: false})

      return cols;

    },

    gotoItem(row) {
      this.$router.push({name: 'Good', params:{id: row.id}});
    }

  }
}
</script>
